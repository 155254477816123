$(function() {
	$('.hamburger-button').on('click', function(event){
		event.preventDefault();

		$(this).toggleClass('active');
		$('.nav-wrapper').toggleClass('visible');
	});



		var cbpAnimatedHeader = (function() {

		var docElem = document.documentElement,
			header = document.querySelector( '.header-bar' ),
			didScroll = false,
			changeHeaderOn = 150;

		function init() {
			window.addEventListener( 'scroll', function( event ) {
				if( !didScroll ) {
					didScroll = true;
					setTimeout( scrollPage, 100 );
				}
			}, false );
		}

		function scrollPage() {
			var sy = scrollY();
			if ( sy >= changeHeaderOn ) {
				classie.add( header, 'header-bar-shrink' );
			}
			else {
				classie.remove( header, 'header-bar-shrink' );
			}
			didScroll = false;
		}

		function scrollY() {
			return window.pageYOffset || docElem.scrollTop;
		}

		init();

	})();



});
